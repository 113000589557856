import config from './config';

export default {
  items: [
    {
      name: 'Dashboard',
      icon: 'icon-speedometer',
      url: '/dashboard',
    },
    {
      title: false,
      name: 'Audience',
      icon: 'icon-people',
      children: [{
        name: 'Users Insights',
        url: '/user-list',
        icon: 'icon-user',
      }, {
        name: 'Users Overview',
        url: '/audience-overview',
        icon: 'icon-user',
      },
      {
        name: 'Users Weekly Insight',
        url: '/user-insight',
        icon: 'icon-user',
      },
      {
        name: 'Users Weekly Install',
        url: '/user-install',
        icon: 'icon-user',
      },
      {
        name: 'Users Weekly Paid',
        url: '/user-paid',
        icon: 'icon-user',
      }
    ]
    },
    {
      name: 'Data Metrics',
      title: false,
      icon: 'cui-monitor',
      children: [{
        name: 'Analytics Metrics',
        url: '/metrics',
        icon: 'cui-monitor',
      },
      {
        name: 'Daily Metrics',
        url: '/daily-metrics',
        icon: 'fa fa-cubes',
      },
      {
        name: 'Analytics Funnel',
        url: '/analytics-funnel',
        icon: 'fa fa-line-chart',
      },
    ]
    },
    {
      name: 'User Retention',
      icon: 'icon-people',
      children: [
        {
        name: 'Monthly Sign-Up',
        icon: 'icon-organization',
        url: '/monthly-signup',
      },
      {
        name: 'Monthly Retention',
        icon: 'icon-calendar',
        url: '/monthly-retention',
      },
      {
        name: 'Weekly Retention',
        icon: 'icon-calendar',
        url: '/weekly-retention',
      },
      {
        name: 'KPI\'S',
        icon: 'icon-graph',
        url: '/retention-kpi',
      }
    ]
    },
    // {
    //   name: 'User Retention',
    //   icon: 'icon-people',
    //   url: '/retention',
    // },
    {
      name: 'Analytics Reports',
      icon: 'icon-people',
      url: '/analytics-reports'
    },
    {
      name: 'Content Admin',
      url: config.NINJA_ADMIN_URL,
      icon: 'fa fa-user',
      class: '',
      attributes: { rel: "noopener" },
    },
  ],
};
